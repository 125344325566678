import {
  BasicCard,
  BottomFormAction,
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
} from "components";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomDatepickerForm,
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  RadioGroupForm,
  SelectChartOfAccountForm,
} from "components/HookForm";
import FilePickerForm from "components/HookForm/FilePickerForm";
import { find, map, sum, sumBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useLocation, useNavigate, useParams } from "react-router";
import Contractual from "./Contractual";
import ThirdParty from "./ThirdParty";
import NonContractual from "./NonContractual";
import {
  useCreateContractual,
  useSubmissionDetailQuery,
  useUpdateContractual,
} from "hooks/directTransaction";
import {
  getContractualPayload,
  getNonContractualParticipantPayload,
  getNonContractualStaffPayload,
} from "./util";
import { NumericFormat } from "react-number-format";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { getFileFromUrl } from "pages/DownPayment/util";
import Vendor from "./Vendor";

const DirectTransactionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const isEditing = !!params?.id;

  const links = [
    {
      label: "Transaksi Langsung (LS)",
      path: "/direct-transactions",
    },
    {
      label: "Daftar Pengajuan LS",
      path: "/direct-transactions",
    },
    {
      label: "Detail Kegiatan",
    },
  ];

  const paymentTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Kontraktual" },
      { value: 2, label: "Pembayaran Non Kontraktual" },
      { value: 3, label: "Pembayaran Vendor" },
    ],
    []
  );

  const trxTypeOptions = useMemo(
    () => [
      { value: 1, label: "Pembayaran Perjadin Pegawai" },
      { value: 2, label: "Pembayaran Undangan" },
      { value: 3, label: "Pembayaran Narasumber" },
    ],
    []
  );

  const { data: detail } = useSubmissionDetailQuery(
    ["submission-detail", { id: params?.id }],
    { enabled: isEditing }
  );

  const methods = useForm({
    defaultValues: {
      // description: "",
      // transaction_date: "",
      // invoice_number: "",
      // component: "",
      // total_trx_value: "",
      // thirdparty: {
      //   vendor_procurement: null,
      //   vendor: null,
      //   account_name: "",
      //   bank: "",
      // },
      // accounts: [],
    },
  });

  const handleTrxType = () => {
    switch (detail?.result?.transaction_type) {
      case "staff":
        return 1;
      case "invite":
        return 2;
      case "expert":
        return 3;
      default:
        return null;
    }
  };

  const setDefaultValues = async (data) => {
    const supportingDoc = await getFileFromUrl(
      data?.documents?.[0]?.url,
      data?.documents?.[0]?.file_name
    );
    if (data?.payment_type === "non_contractual") {
      methods.reset({
        description: data?.description,
        payment_type: find(paymentTypeOptions, { value: 2 }),
        transaction_date: moment(data?.transaction_date).toDate(),
        trx_type:
          data?.transaction_type === "staff"
            ? { value: 1, label: "Pembayaran Perjadin Pegawai" }
            : data?.transaction_type === "invite"
            ? { value: 2, label: "Pembayaran Undangan" }
            : { value: 3, label: "Pembayaran Narasumber" },
        invoice_number: data?.invoice_number,
        component: {
          value: data?.component_code,
          label: data?.component_code,
          full_code: data?.component_code,
        },
        total_trx_value: data?.total_value,
        accounts: map(data?.accounts, (account) => {
          return {
            shipping_account: {
              value: account?.account_code,
              label: account?.account_code,
              full_code: account?.account_code,
            },
            activity_detail: {
              value: account?.detail_code,
              label: account?.detail_code,
              full_code: account?.detail_code,
            },
            details: map(account?.details, (detail) => ({
              detailInfo: detail?.participant,
              name: detail?.participant?.name,
              is_ministry_employee: detail?.ministry_staff,
              requirement_item: {
                ...detail?.sbm,
                sbm_value: detail?.sbm?.value,
                value: detail?.sbm?.id,
                label: detail?.sbm?.description,
              },
              qty: detail?.qty,
              value: detail?.value,
              sub_total: detail?.subtotal,
              taxes: map(detail?.taxes, (tax) => ({
                tax: { value: tax?.tax_id, label: tax?.tax_name },
                billing_code: tax?.billing_code,
                value: tax?.value,
              })),
              total_taxes: detail?.taxes?.reduce(
                (acc, o) => acc + o?.value,
                0
              ),
              account_number: detail?.bank_account_number,
              account_name: detail?.bank_account_name,
              bank: detail?.bank_name,
              total: detail?.total_value,
            })),
          };
        }),
      });
    } else {
      methods.reset({
        description: data?.description,
        payment_type:
          data?.payment_type === "contractual"
            ? find(paymentTypeOptions, { value: 1 })
            : data?.payment_type === "non_contractual"
            ? find(paymentTypeOptions, { value: 2 })
            : find(paymentTypeOptions, { value: 3 }),
        transaction_date: moment(data?.transaction_date).toDate(),
        trx_type: find(trxTypeOptions, (type) => handleTrxType() === type?.value),
        invoice_number: data?.invoice_number,
        component: {
          value: data?.component_code,
          label: data?.component_code,
          full_code: data?.component_code,
        },
        total_trx_value: data?.total_value,
        accounts: map(data?.accounts, (account) => ({
          shipping_account: {
            value: account?.account_code,
            label: account?.account_code,
            full_code: account?.account_code,
          },
          activity_details: map(account?.details, (detail) => ({
            activity_detail: {
              value: detail?.detail_code,
              label: detail?.detail_code,
              full_code: detail?.detail_code,
            },
            value: detail?.value,
            unit_cost: detail?.value,
            qty: detail?.qty,
            volume: detail?.volume,
            taxes: map(detail?.taxes, (tax) => ({
              tax: {
                value: tax?.tax_id,
                label: tax?.tax_name,
              },
              billing_code: tax?.billing_code,
              value: tax?.value,
            })),
          })),
        })),
        thirdparty: {
          vendor_procurement: {
            value: data?.vendor?.vendor_id,
            label: data?.vendor?.name,
          },
          vendor: {
            value: data?.vendor?.bank_account_number,
            label: data?.vendor?.bank_account_number,
          },
          account_name: data?.vendor?.bank_account_name,
          bank: data?.vendor?.bank_name,
        },
        supporting_document: [supportingDoc],
      });
    }
  };

  useEffect(() => {
    if (detail?.result) {
      const data = detail?.result;
      setDefaultValues(data);
    }
  }, [detail?.result]);

  return (
    <>
      <Breadcrumbs items={links} />
      <div className="h-3" />
      <FormProvider {...methods}>
        <div className="space-y-4">
          <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border">
            <div className="card-body p-4 space-y-4">
              <div className="text-lg font-semibold mb-3">
                {isEditing
                  ? "Edit Kegiatan Untuk Pengajuan LS"
                  : "Detail Kegiatan Untuk Pengajuan LS"}
              </div>
              <InputForm
                name={`description`}
                type="textarea"
                className={`py-2 px-4 border w-full rounded-md`}
                label={"Uraian Pengajuan"}
                required={true}
                disabled
              />
              <CustomSelectForm
                name="payment_type"
                options={paymentTypeOptions}
                label="Jenis Pembayaran"
                placeholder="Pilih jenis pembayaran"
                isDisabled
              />
              <div
                className={`grid grid-cols-1 ${
                  methods.watch("payment_type")?.value === 2
                    ? "md:grid-cols-3"
                    : "md:grid-cols-2"
                } gap-4`}
              >
                <CustomDatepickerForm
                  name="transaction_date"
                  label="Tanggal SPJ / Tagihan / Transaksi"
                  placeholder={"dd/mm/yyyy"}
                  disabled
                />
                {methods.watch("payment_type")?.value === 2 && (
                  <CustomSelectForm
                    name="trx_type"
                    options={trxTypeOptions}
                    label="Jenis Transaksi"
                    placeholder="Pilih jenis transaksi"
                    isDisabled
                  />
                )}
                <InputForm
                  name={`invoice_number`}
                  label={"Nomor Invoice"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  placeholder="INV/--/---/---/----"
                  disabled
                />
              </div>
              {location?.state?.role !== "pjk" && (
                <>
                  {/* <CustomSelectForm
                    name="activity"
                    options={[]}
                    label="Kegiatan"
                    placeholder="Pilih kegiatan"
                    isDisabled
                  /> */}
                  {/* <CustomSelectForm
                    name="output_detail"
                    options={[]}
                    label="Rincian Output"
                    placeholder="Pilih rincian output"
                    isDisabled
                  /> */}
                </>
              )}
              <SelectChartOfAccountForm
                name="component"
                className="w-full"
                label="Komponen"
                placeholder="Pilih komponen"
                level={9}
                menuPortalTarget={document.body}
                isDisabled
              />
              {/* <div className="space-y-2">
                <label className="flex gap-1 font-semibold text-[14px]">
                  <span className={`label-text`}>Total Nilai Transaksi</span>
                </label>
                <NumericFormat
                  value={sumBy(methods.watch("accounts"), (account) =>
                    sumBy(
                      account.activity_details,
                      (activity_detail) =>
                        parseFloat(activity_detail.unit_cost) *
                          (parseFloat(activity_detail.volume) +
                            parseFloat(activity_detail.qty)) -
                        parseFloat(
                          activity_detail.taxes?.reduce(
                            (acc, o) => acc + o?.value,
                            0
                          )
                        )
                    )
                  )}
                  placeholder="0"
                  className="w-full"
                  customInput={Input}
                  allowLeadingZeros
                  thousandSeparator="."
                  decimalSeparator=","
                  disabled
                />
              </div> */}

              <NumberInputForm
                disabled
                name={`total_trx_value`}
                label={"Total Nilai Transaksi"}
                className={`py-2 px-4 border w-full rounded-md`}
              />

              {(methods.watch("payment_type")?.value === 1) && (
                <Contractual disabled />
              )}

              {(methods.watch("payment_type")?.value === 3) && (
                <Vendor disabled />
              )}
            </div>
          </div>

          {(methods.watch("payment_type")?.value === 1 ||
            methods.watch("payment_type")?.value === 3) && (
            <ThirdParty disabled />
          )}
          {methods.watch("payment_type")?.value === 2 &&
            methods.watch("trx_type")?.value && <NonContractual disabled />}

          <Card>
            <CardHeader>Dokumen Bukti Pendukung</CardHeader>
            <CardBody>
              <FilePickerForm
                name="supporting_document"
                label="Dokumen Bukti Pendukung"
                file={map(methods.watch("supporting_document"), (sd) => ({
                  ...sd,
                  file_name: sd?.name,
                  file_size: sd?.size,
                  url: detail?.result?.documents?.[0]?.url,
                }))}
                readOnly
              />
            </CardBody>
          </Card>
          <div className="h-20" />
        </div>
      </FormProvider>
    </>
  );
};

export default DirectTransactionDetail;
