import { useMutation, useQuery } from "react-query";
import { ES } from "services";

export const useCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: ({ signal }) => {
      return ES.getCountries(signal);
    },
  });
};

export const useCities = () => {
  return useQuery({
    queryKey: ["cities"],
    queryFn: ({ signal }) => {
      return ES.getCities(signal);
    },
  });
};

export const useUploadFile = () => {
  return useMutation((payload) => ES.uploadFile(payload));
};

export const useEmployees = () => {
  return useQuery({
    queryKey: ["employees"],
    queryFn: ({ signal }) => {
      return ES.getEmployees(signal);
    },
  });
};

export const useEmployeesNonProject = () => {
  return useQuery({
    queryKey: ["employees-non-project"],
    queryFn: ({ signal }) => {
      return ES.getEmployeesNonProject(signal);
    },
  });
};

export const useTaxesQuery = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return ES.getTaxes({ ...params }, signal);
    },
    ...options,
  });
};
